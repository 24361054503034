import React, { useState, useEffect, useCallback, useRef } from "react";
import RangeSlider from "react-range-slider-input";
import "react-range-slider-input/dist/style.css";
// import Button from "../../components/Button/button";
import { useTranslation } from "react-i18next";
import { ToastContainer, toast } from "react-toastify";

import Tick1 from "../assects/images/tick1 (1).png";
import Tierimg from "../assects/images/Tierimg.png";

import cross from "../assects/images/xcs.svg";
import info from "../assects/svgs/info.svg";
import Crown from "../assects/images/rng (5).png";
import R1 from "../assects/images/rng (2).webp";
import R2 from "../assects/images/rng (3).png";
import R3 from "../assects/images/rng (1).png";
import R4 from "../assects/images/rng (4).webp";

import { useReadContract, useSwitchChain, useWriteContract } from "wagmi";
// import RewardCard from "./RewardsCard";
import Dicn1 from "../assects/svgs/heddropicn (4).svg"
import Dicn2 from "../assects/svgs/heddropicn (3).svg"
import Dicn3 from "../assects/svgs/heddropicn (2).svg"
import Dicn4 from "../assects/svgs/heddropicn (1).svg"
import Dicnclose from "../assects/svgs/heddropicncls.svg"
import RewardCard from '../Page/Home/RewardsCard';
import Button from './Button/button';

import Ferrari from "../assects/images/rng (5).png";
import Mercedes from "../assects/images/rng (2).png";
import Porsche from "../assects/images/rng (3).png";
import Bentley from "../assects/images/rng (1).png";
import Lamborghini from "../assects/images/rng (4).png";
import {
  useDisconnect,

} from 'wagmi'

import { bsc } from 'wagmi/chains'
const PersonaldashData = [
  {
    id: 1,
    title: "DCARS",
    img: Dicn1,
  },
  {
    id: 2,
    title: "Bonus",
    img: Dicn2,
  },
  {
    id: 3,
    title: "Staking Rewards",
    img: Dicn3,
  },
  {
    id: 4,
    title: "DCARS NFT",
    img: Dicn4,
  },
]

function PersonalDashboard({ accounts, handleClose }) {
  const { t, i18n } = useTranslation();

  const [rewardAmount, setRewardAmount] = useState(1000);
  const [amountRange, setAmountRange] = useState(1000);
  const [range, setRange] = useState(1000);

  const dashboardRef = useRef(null);

  // const handleClickOutside = (event) => {
  //   if (dashboardRef.current && !dashboardRef.current.contains(event.target)) {
  //     handleButtonClickClose(); // Close the dashboard
  //   }
  // };

  // useEffect(() => {
  //   document.addEventListener("mousedown", handleClickOutside);
  //   return () => {
  //     document.removeEventListener("mousedown", handleClickOutside);
  //   };
  // }, []);

  const handleRange = (range) => {
    setRange(range);
  };
  const handleImageClick = (message) => {
    toast.info(message, {
      position: "bottom-right",
      autoClose: true,
      hideProgressBar: true,
      closeOnClick: false,
      pauseOnHover: true,
      draggable: false,
      progress: false,
      theme: "light",
    });
  };
  const handleCopy = () => {
    navigator.clipboard.writeText("https://dreamcars.co/")
      .then(() => {
        let link = window.location.origin + "/?ref=" + accounts + "&lang=" + i18n.language
        navigator.clipboard.writeText(link);
        toast.success('Copied!');
      })
      .catch((error) => {
        console.error('Error copying:', error);
        toast.error('Failed to copy.');
      });
  };

  
  const handleClaimBtn = () => {
        toast.info('You can claim your $DCARS once the pre-sale is completed');
  };

  const { chains, switchChain } = useSwitchChain()

  const { data: userContribution } = useReadContract({
    address: '0x883178a1571094a6656cca084f3ed5a3192abaf3',
    abi: [{
      "inputs": [
        {
          "internalType": "address",
          "name": "_userAddress",
          "type": "address"
        }
      ],
      "name": "getUserContribiton",
      "outputs": [
        {
          "internalType": "uint256",
          "name": "",
          "type": "uint256"
        }
      ],
      "stateMutability": "view",
      "type": "function"
    }],
    functionName: 'getUserContribiton',
    chainId: 56,
    args: [accounts],
  })

  const { data: getClaimTokensData, write: getClaimTokens } = useWriteContract()


  const { data: userClaimed } = useReadContract({
    address: '0x316ff8185a7356dfa00309f88d203c87a20f64c0',
    abi: [{
      "inputs": [
        {
          "internalType": "address",
          "name": "",
          "type": "address"
        }
      ],
      "name": "userClaimedUSDT",
      "outputs": [
        {
          "internalType": "uint256",
          "name": "",
          "type": "uint256"
        }
      ],
      "stateMutability": "view",
      "type": "function"
    }],
    functionName: 'userClaimedUSDT',
    chainId: 56,
    args: [accounts],
  })

  const { data: userClaimedETH } = useReadContract({
    address: '0x3F5C9557dB581Fb853AC541C171766c3E77C3e06',
    abi: [{
      "inputs": [
        {
          "internalType": "address",
          "name": "",
          "type": "address"
        }
      ],
      "name": "userClaimedUSDT",
      "outputs": [
        {
          "internalType": "uint256",
          "name": "",
          "type": "uint256"
        }
      ],
      "stateMutability": "view",
      "type": "function"
    }],
    functionName: 'userClaimedUSDT',
    chainId: 1,
    args: [accounts],
  })

  const { data: userTokensETH, isSuccess: successUserTokensETH } = useReadContract({
    address: '0x076b20436b7cf3d461b5b2260a9eeee1967b1dba',
    abi: [{
      "inputs": [
        {
          "internalType": "address",
          "name": "_userAddress",
          "type": "address"
        }
      ],
      "name": "getUserTokens",
      "outputs": [
        {
          "internalType": "uint256",
          "name": "",
          "type": "uint256"
        }
      ],
      "stateMutability": "view",
      "type": "function"
    }],
    functionName: 'getUserTokens',
    chainId: 1,
    args: [accounts],
  })

  const { data: userBonusETH, isSuccess: successUserBonusTokensETH } = useReadContract({
    address: '0x076b20436b7cf3d461b5b2260a9eeee1967b1dba',
    abi: [{
      "inputs": [
        {
          "internalType": "address",
          "name": "_userAddress",
          "type": "address"
        }
      ],
      "name": "getUserBonusTokens",
      "outputs": [
        {
          "internalType": "uint256",
          "name": "",
          "type": "uint256"
        }
      ],
      "stateMutability": "view",
      "type": "function"
    }],
    functionName: 'getUserBonusTokens',
    chainId: 1,
    args: [accounts],
  })
  const { data: userContributionETH } = useReadContract({
    address: '0x076b20436b7cf3d461b5b2260a9eeee1967b1dba',
    abi: [{
      "inputs": [
        {
          "internalType": "address",
          "name": "_userAddress",
          "type": "address"
        }
      ],
      "name": "getUserContribiton",
      "outputs": [
        {
          "internalType": "uint256",
          "name": "",
          "type": "uint256"
        }
      ],
      "stateMutability": "view",
      "type": "function"
    }],
    functionName: 'getUserContribiton',
    chainId: 1,
    args: [accounts],
  })

  const { data: getUSDTData, write: getUSDT } = useWriteContract({
    address: '0x316ff8185a7356dfa00309f88d203c87a20f64c0',
    abi: [{
      "inputs": [],
      "name": "getUSDT",
      "outputs": [],
      "stateMutability": "nonpayable",
      "type": "function"
    }],
    functionName: 'getUSDT',
    chainId: bsc.id
  })

  const { data: getUSDTDataETH, write: getUSDTETH } = useWriteContract()

  const { data: countAllStakingProfit, refetch: refreshContributorsETH } = useReadContract({
    address: '0x316ff8185a7356dfa00309f88d203c87a20f64c0',
    abi: [{
      "inputs": [],
      "name": "countAllStakingProfit",
      "outputs": [
        {
          "internalType": "uint256",
          "name": "",
          "type": "uint256"
        }
      ],
      "stateMutability": "view",
      "type": "function"
    }],
    functionName: 'countAllStakingProfit',
    chainId: 56
  })

  const { data: cdaysBNB, refetch: refetchcdays } = useReadContract({
    address: '0x316ff8185a7356dfa00309f88d203c87a20f64c0',
    abi: [
      {
        "inputs": [
          {
            "internalType": "address",
            "name": "",
            "type": "address"
          }
        ],
        "name": "userClaimTimes",
        "outputs": [
          {
            "internalType": "uint256",
            "name": "",
            "type": "uint256"
          }
        ],
        "stateMutability": "view",
        "type": "function"
      }],
    functionName: 'userClaimTimes',
    chainId: 56,
    args: [accounts],
  })

  const { data: cdaysETH } = useReadContract({
    address: '0x3F5C9557dB581Fb853AC541C171766c3E77C3e06',
    abi: [
      {
        "inputs": [
          {
            "internalType": "address",
            "name": "",
            "type": "address"
          }
        ],
        "name": "userClaimTimes",
        "outputs": [
          {
            "internalType": "uint256",
            "name": "",
            "type": "uint256"
          }
        ],
        "stateMutability": "view",
        "type": "function"
      }],
    functionName: 'userClaimTimes',
    chainId: 1,
    args: [accounts],
  })
  const handleUSDT = () => {
    if (chains?.id === 56) {
      getUSDT?.()
    }
    if (chains?.id === 1) {
      getUSDTETH({
        address: '0x3F5C9557dB581Fb853AC541C171766c3E77C3e06',
        abi: [{
          "inputs": [],
          "name": "getUSDT",
          "outputs": [],
          "stateMutability": "nonpayable",
          "type": "function"
        }],
        functionName: 'getUSDT',
        chainId: 1
      })
    }
  }
  const handleClaim = () => {
    if (chains?.id === 56) {
      getClaimTokens({
        address: '0x5d8b4539dff12d6b746c6e0622d88104e3593825',
        abi: [{
          "inputs": [],
          "name": "claimTokens",
          "outputs": [],
          "stateMutability": "nonpayable",
          "type": "function"
        }],
        functionName: 'claimTokens',
        chainId: bsc.id
      })
    }
  }
  const [totalProfit, setTotalProfit] = useState(0);
  const [usdtProfit, setUsdtProfit] = useState(0);
  const month = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
  const disconnect = useDisconnect({
    onSuccess(data) {
      console.log('Success', data)
    },
    onSettled(data, error) {
      console.log('Settled', { data, error })
    },
    onError(error) {
      console.log('Error', error)
    },
    onMutate(args) {
      console.log('Mutate', args)
    },

  })

  const [nft, setNFT] = useState(0)
  const [nftData, setNFTData] = useState("No NFT")
  const [nextNFT, setNextNFT] = useState("Bentley")
  const [nextNFTNum, setNextNFTNum] = useState(1)
  const [nextNftImage, setNextNftImage] = useState(Porsche)
  const [nextNftGoal, setNextNftGoal] = useState(1000)
  const [nftImage, setNFTImage] = useState(Mercedes)

  const listData = [
    {
      id: 1,
      packageType: "RewardSection.packageType1",
      spendPrice: amountRange,
      spendImage: R2,

      packageList: [
        {
          id: 1,
          icon: Tick1,
          text: "RewardSection.packageText11",
          info: "RewardSection.packageinfo11",
          infotitle: "RewardSection.packageinfotitle11",

        },
        {
          id: 2,
          icon: Tick1,
          text: "RewardSection.packageText12",
          infotitle: "RewardSection.packageinfotitle12",
          info: "RewardSection.packageinfo12",

        },
        {
          id: 3,
          icon: Tick1,
          text: "RewardSection.packageText13",
          infotitle: "RewardSection.packageinfotitle13",
          info: "RewardSection.packageinfo13",

        },
        {
          id: 4,
          icon: cross,
          infotitle: "RewardSection.packageinfotitle14",
          text: "RewardSection.packageText14",
          info: "RewardSection.packageinfo14",

        },
        {
          id: 5,
          icon: cross,
          text: "RewardSection.packageText15",
          infotitle: "RewardSection.packageinfotitle15",
          info: "RewardSection.packageinfo15",

        },
        {
          id: 6,
          icon: cross,
          text: "RewardSection.packageText16",
        },
      ],
    },
    {
      id: 2,
      packageType: "RewardSection.packageType2",
      spendPrice: amountRange,
      spendImage: R1,
      packageList: [
        {
          id: 1,
          icon: Tick1,
          text: "RewardSection.packageText21",
          info: "RewardSection.packageinfo12",
          infotitle: "RewardSection.packageinfotitle11",
        },
        {
          id: 2,
          icon: Tick1,
          text: "RewardSection.packageText22",
          info: "RewardSection.packageinfo12",
          infotitle: "RewardSection.packageinfotitle12",
        },
        {
          id: 3,
          icon: Tick1,
          text: "RewardSection.packageText23",
          info: "RewardSection.packageinfo13",
          infotitle: "RewardSection.packageinfotitle13",
        },
        {
          id: 4,
          icon: Tick1,
          text: "RewardSection.packageText24",
          info: "RewardSection.packageinfo14",
          infotitle: "RewardSection.packageinfotitle14",
        },
        {
          id: 5,
          icon: cross,
          text: "RewardSection.packageText25",
          info: "RewardSection.packageinfo15",
          infotitle: "RewardSection.packageinfotitle15",
        },
        {
          id: 6,
          icon: cross,
          text: "RewardSection.packageText16",
        },
      ],
    },
    {
      id: 3,
      packageType: "RewardSection.packageType3",
      spendPrice: amountRange,
      spendImage: R3,
      packageList: [
        {
          id: 1,
          icon: Tick1,
          text: "RewardSection.packageText31",
          info: "RewardSection.packageinfo13",
          infotitle: "RewardSection.packageinfotitle11",
        },
        {
          id: 2,
          icon: Tick1,
          text: "RewardSection.packageText32",
          info: "RewardSection.packageinfo12",
          infotitle: "RewardSection.packageinfotitle12",
        },
        {
          id: 3,
          icon: Tick1,
          text: "RewardSection.packageText33",
          info: "RewardSection.packageinfo13",
          infotitle: "RewardSection.packageinfotitle13",
        },
        {
          id: 4,
          icon: Tick1,
          text: "RewardSection.packageText34",
          info: "RewardSection.packageinfo14",
          infotitle: "RewardSection.packageinfotitle14",
        },
        {
          id: 5,
          icon: Tick1,
          text: "RewardSection.packageText35",
          info: "RewardSection.packageinfo15",
          infotitle: "RewardSection.packageinfotitle15",
        },
        {
          id: 6,
          icon: cross,
          text: "RewardSection.packageText16",
        },
      ],
    },
    {
      id: 4,
      packageType: "RewardSection.packageType4",
      spendPrice: amountRange,
      spendImage: Crown,
      packageList: [
        {
          id: 1,
          icon: Tick1,
          text: "RewardSection.packageText41",
          info: "RewardSection.packageinfo14",
          infotitle: "RewardSection.packageinfotitle11",
        },
        {
          id: 2,
          icon: Tick1,
          text: "RewardSection.packageText42",
          info: "RewardSection.packageinfo12",
          infotitle: "RewardSection.packageinfotitle12",
        },
        {
          id: 3,
          icon: Tick1,
          text: "RewardSection.packageText43",
          info: "RewardSection.packageinfo13",
          infotitle: "RewardSection.packageinfotitle13",
        },
        {
          id: 4,
          icon: Tick1,
          text: "RewardSection.packageText44",
          info: "RewardSection.packageinfo14",
          infotitle: "RewardSection.packageinfotitle14",
        },
        {
          id: 5,
          icon: Tick1,
          text: "RewardSection.packageText45",
          info: "RewardSection.packageinfo45",
          infotitle: "RewardSection.packageinfotitle15",
        },
        {
          id: 6,
          icon: Tick1,
          text: "RewardSection.packageText16",
        },
      ],
    },
    {
      id: 5,
      packageType: "RewardSection.packageType5",
      spendPrice: amountRange,
      spendImage: R4,
      packageList: [
        {
          id: 1,
          icon: Tick1,
          text: "RewardSection.packageText51",
          info: "RewardSection.packageinfo15",
          infotitle: "RewardSection.packageinfotitle11",
        },
        {
          id: 2,
          icon: Tick1,
          text: "RewardSection.packageText52",
          info: "RewardSection.packageinfo12",
          infotitle: "RewardSection.packageinfotitle12",
        },
        {
          id: 3,
          icon: Tick1,
          text: "RewardSection.packageText53",
          info: "RewardSection.packageinfo13",
          infotitle: "RewardSection.packageinfotitle13",
        },
        {
          id: 4,
          icon: Tick1,
          text: "RewardSection.packageText54",
          info: "RewardSection.packageinfo14",
          infotitle: "RewardSection.packageinfotitle14",
        },
        {
          id: 5,
          icon: Tick1,
          text: "RewardSection.packageText55",
          info: "RewardSection.packageinfo55",
          infotitle: "RewardSection.packageinfotitle15",
        },
        {
          id: 6,
          icon: Tick1,
          text: "RewardSection.packageText16",
        },
      ],
    },
  ];
  function truncateToDecimals(num, dec = 2) {
    const calcDec = 10 ** dec
    return Math.trunc(num * calcDec) / calcDec;
  }

  const [finalUserTokens, setFinalUserTokens] = useState(0)
  const [finalBonusTokens, setFinalBonusTokens] = useState(0)
  const [finalUserContribution, setFinalUserContribution] = useState(0)

  const { data: userTokens, isSuccess: successUserTokens } = useReadContract({
    address: '0x883178a1571094a6656cca084f3ed5a3192abaf3',
    abi: [{
      "inputs": [
        {
          "internalType": "address",
          "name": "_userAddress",
          "type": "address"
        }
      ],
      "name": "getUserTokens",
      "outputs": [
        {
          "internalType": "uint256",
          "name": "",
          "type": "uint256"
        }
      ],
      "stateMutability": "view",
      "type": "function"
    }],
    functionName: 'getUserTokens',
    chainId: 56,
    args: [accounts],
  })


  const { data: userBonus, isSuccess: successUserBonusTokens } = useReadContract({
    address: '0x883178a1571094a6656cca084f3ed5a3192abaf3',
    abi: [{
      "inputs": [
        {
          "internalType": "address",
          "name": "_userAddress",
          "type": "address"
        }
      ],
      "name": "getUserBonusTokens",
      "outputs": [
        {
          "internalType": "uint256",
          "name": "",
          "type": "uint256"
        }
      ],
      "stateMutability": "view",
      "type": "function"
    }],
    functionName: 'getUserBonusTokens',
    chainId: 56,
    args: [accounts],
  })


  const handleRef = () => {
    toast.success("Copied Referral Link")
    let link = window.location.origin + "/?ref=" + accounts + "&lang=" + i18n.language
    navigator.clipboard.writeText(link);

  }
  useEffect(() => {
    setFinalUserTokens(Number(userTokens) / 1e18 + Number(userTokensETH) / 1e18)

    setFinalBonusTokens(Number(userBonusETH) / 1e18 + Number(userBonus) / 1e18)
    setFinalUserContribution(Number(userContribution) / 1e6 + Number(userContributionETH) / 1e6)

  }, [successUserBonusTokens, successUserTokens, successUserBonusTokensETH, successUserTokensETH])

  useEffect(() => {
    if (finalUserContribution >= 1000) {
      setNFT(0.1)
      setNFTData("Mercedes")
      setNFTImage(Mercedes)
      setNextNFT("Porsche")
      setNextNFTNum(2)
      setNextNftGoal(5000)
      setAmountRange(5000)
      setNextNftImage(Porsche)
    }
    if (finalUserContribution >= 5000) {
      setNFT(0.15)
      setNextNFTNum(3)
      setNFTData("Porsche")
      setNFTImage(Porsche)
      setNextNFT("Bentley")
      setNextNftGoal(10000)
      setAmountRange(10000)
      setNextNftImage(Bentley)
    }
    if (finalUserContribution >= 10000) {
      setNFT(0.25)
      setNFTData("Bentley")
      setNextNFTNum(4)
      setNFTImage(Bentley)
      setNextNFT("Ferrari")
      setNextNftGoal(25000)
      setAmountRange(25000)
      setNextNftImage(Ferrari)
    }
    if (finalUserContribution >= 25000) {
      setNFT(0.4)
      setNFTData("Ferrari")
      setNextNFTNum(5)
      setNFTImage(Ferrari)
      setNextNftGoal(100000)
      setAmountRange(100000)
      setNextNFT("Lamborghini")
      setNextNftImage(Lamborghini)
    }
    if (finalUserContribution >= 100000) {
      setNFT(0.6)
      setNFTData("Lamborghini")
      setNextNFTNum(5)
      setNFTImage(Lamborghini)
    }

  }, [finalUserContribution])

  return (
    <>
      <div className='pb-[4rem] space-y-[15px] !w-[100%] px-[2rem] pt-[10px] relative' ref={dashboardRef}>
        <img onClick={()=>handleClose(false)} className='right-[6%] absolute cursor-pointer top-[0.9%]' src={Dicnclose} alt="" />
        <h3 className='text-[22px] font-[900] !m-0 leading-[130%] text-center text-[#fff]'>Personal Dashboard</h3>
        <div className=' mx-[3px] backdrop-blur-md bg-[#d1d5db1f] gradient-border-mask-per-hed rounded-[12px] py-[8px]'>
          <div className='relative z-[9]'>
            <p className='text-[#FFD02F] text-center text-[16px]  font-[900]'>Wallet Address</p>
            <p className='text-[#fff] text-[14px] font-[400] text-center'>{accounts.slice(0, 10)}...{accounts.slice(-10)}</p>
            
          </div>
        </div>
        <div className='px-[2rem] py-[0.6rem] mx-[3px] backdrop-blur-md bg-[#d1d5db1f] gradient-border-mask-per-hed rounded-[12px]'>
          <div className='relative z-[9]'>
            <p className='text-[#FFD02F] text-center text-[16px]  font-[900]'>Launch Price</p>
            <h4 className='text-[16px] text-[#fff] font-[900] text-center'>1 DCARS = $0.03</h4>
          </div>
        </div>
        <div className='space-y-[10px]'>
          <h4 className='text-center text-[16px] text-[#fff] font-[400] leding-[130%]'>
            {`${month[new Date().getMonth()]} ${new Date().getDate()} ,  ${new Date().getFullYear()}`}</h4>
          <div>
            <div className='space-y-[10px] mx-[3px]'>

              <div className='flex items-center justify-between backdrop-blur-md bg-[#d1d5db1f] gradient-border-mask-per-hed rounded-[12px] px-[15px] py-[0.6rem]'>
                <div className='flex space-x-2 items-center'>
                  <img src={Dicn1} className='h-[20px]' alt="" />
                  <h4 className='text-[16px] font-[700] text-[#fff] leading-[120%]'>DCARS</h4>
                </div>
                <div>
                  <p className='text-[#FFD02F] font-[700] text-[16px]'>{finalUserTokens.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</p>
                </div>
              </div>


              <div className='flex items-center justify-between backdrop-blur-md bg-[#d1d5db1f] gradient-border-mask-per-hed rounded-[12px] px-[15px] py-[0.6rem]'>
                <div className='flex space-x-2 items-center'>
                  <img src={Dicn2} className='h-[20px]' alt="" />
                  <h4 className='text-[16px] font-[700] text-[#fff] leading-[120%]'>Bonus</h4>
                </div>
                <div>
                  <p className='text-[#FFD02F] font-[700] text-[16px]'>{(finalBonusTokens + (finalUserTokens * nft)).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</p>
                </div>
              </div>


              <div className='flex items-center justify-between backdrop-blur-md bg-[#d1d5db1f] gradient-border-mask-per-hed rounded-[12px] px-[15px] py-[0.6rem]'>
                <div className='flex space-x-2 items-center'>
                  <img src={Dicn3} className='h-[20px]' alt="" />
                  <h4 className='text-[16px] font-[700] text-[#fff] leading-[120%]'>Staking Rewards</h4>
                </div>
                <div>
                  <p className='text-[#FFD02F] font-[700] text-[16px]'>{(finalUserTokens * (totalProfit)).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</p>
                </div>
              </div>


              <div className='flex items-center justify-between backdrop-blur-md bg-[#d1d5db1f] gradient-border-mask-per-hed rounded-[12px] px-[15px] py-[0.6rem]'>
                <div className='flex space-x-2 items-center'>
                  <img src={Dicn4} className='h-[20px]' alt="" />
                  <h4 className='text-[16px] font-[700] text-[#fff] leading-[120%]'>Your Membership NFT</h4>
                </div>
                <div>
                  <p className='text-[#FFD02F] font-[700] text-[16px]'>{nftData} </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>
          <p className='text-[18px] text-[#fff] font-[900] text-center'>Experience even more benefits with</p>
        </div>
        <div className=" 2xl:w-[100%] xl:w-[100%] lg:w-[100%] md:w-[100%] sm:w-[100%] w-[100%]  ">
          {listData.map((item, id, index) =>
            <>
              {Number(item.id) === Number(nextNFTNum) &&
                <div className="py-6 xs:py-3 2xl:px-5 xl:px-5 lg:px-5 md:px-5 sm:px-2 px-2 backdrop-blur-md gradient-border-mask rounded-[34px] bg-[#d1d5db1f]">
                  <div className=" flex justify-between w-[100%] sm:w-[100%] xs:w-[100%]  ">
                    {
                      <>
                        <div className="space-y-2 w-[74%] xs:w-[58%] md:w-[100%] sm:w-full xs:w-full pr-2 xs:pr-0">
                          <div className="flex items-center space-x-[1rem] xs:space-x-2">
                            <img className="h-[30px] xs:h-[40px]" src={Tierimg} alt="" />

                            <h3 className="text-[16px] text-white  sm:text-[18px] xs:text-[16px] font-[700] ">
                              {t(item.packageType)}
                            </h3>
                          </div>
                          <div className="space-y-2 px-5 xs:px-1 ">
                            {item.packageList.map((j, id) => (
                              <div key={id} className="flex space-x-2 items-center ">
                                <img className="w-[14px] h-[14px]" src={j.icon} alt="" />
                                <p className="text-[12px] text-white sm:text-[10px] xs:text-[10px] text-[#222222] font-[400] ">
                                  {t(j.text)}
                                </p>
                                <div className="relative group">
                                  <img src={info} className="cursor-pointer" alt="" />
                                  <div className=" z-[99] absolute bottom-0 left-5   rounded-[18px]  group-hover:flex transition-opacity duration-300 w-[260px] hidden  space-x-3">
                                    <div className="flex-col justify-center items-center flex gradient-border-mask rounded-[34px] backdrop-blur-xl  bg-[#56688494] px-3 py-6 space-y-[8px]">
                                      <img src={info} className="cursor-pointer" alt="" />
                                      <p className="text-[#FFD02F] m-0 text-center text-[20px] font-[600]">{t(j.infotitle)}
                                      </p>
                                      <p className="text-[#fff] m-0 text-center text-[16px] font-[400] leading-[115%]">{t(j.info)}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ))}
                          </div>
                        </div>
                        <div className="space-y-2 xs:space-y-0 flex justify-center relative  w-[40%] xs:w-[80%]">
                          <div key={item.id}
                            className={`absolute top-[-10%]  xs:top-[-1%] min-w-[250px] max-w-[220px] w-[100%] pb-3`}
                            style={{ left: index === 0 ? '-60%' : '-40%' }}>
                            <img
                              className="h-[auto] max-w-[400px] w-[100%] m-auto xs:w-[170px]"
                              src={item.spendImage}
                              alt=""
                            />
                          </div>
                          <div className="absolute spendbg px-[30px] xs:px-[16px] py-[20px] xs:py-[16px] bottom-[5%] tracking-wide xs:bottom-[12%] xs:pt-3 text-center text-[#292A36] font-bold">
                            <h5 className="text-[14px] xs:text-[9px] text-white ">
                              When you spend
                            </h5>
                            <h3 className="text-[16px] sm:text-[10px] text-white xs:text-[10px] font-[Poppins] ">{`$${Number(
                              item.spendPrice
                            ).toLocaleString("en-US")} ${t("RewardSection.more")}`}</h3>
                          </div>
                        </div>
                      </>
                    }
                  </div>
                </div>
              }
            </>
          )}
        </div>
        <div className='' >
          <div className='flex pb-[10px] justify-between items-center'>
            <p className='text-[12px] text-[#fff] font-[400]'>
              {
                finalUserContribution / nextNftGoal * 100 < 100 ?
                  <span style={{}} className="">${(nextNftGoal - finalUserContribution).toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0 })}</span>
                  : 0
              } left to reach the next level </p>
            <p className='text-[#FFD02F] text-[16px] font-[700]'>${nextNftGoal.toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0 })}</p>
          </div>
          <RangeSlider
            className="single-thumb"
            min={1}
            max={100}
            value={[1, finalUserContribution / nextNftGoal * 100]}
            rangeSlideDisabled={true}
            thumbsDisabled={[true, false]}

          />
        </div>
          <Button
            link="#buynow"
            classes="bg-gray-500 relative z-[1] rounded-full w-[100%] h-[45px]  sm:h-[30px]  xs:h-[30px] text-[16px] sm:text-[13px]  xs:text-[13px] font-[700]  m-auto "
            text="Claim $DCARS"
            onClick={handleClaimBtn}
          />{" "}
        <div className='pt-[0.2rem] flex justify-between space-x-1'>
          <Button
            link="#buynow"
            classes="bgcolor relative z-[1] rounded-full w-[100%] h-[45px]  sm:h-[30px]  xs:h-[30px] text-[16px] sm:text-[13px]  xs:text-[13px] font-[700]  m-auto "
            text="Copy Referral Link"
            onClick={handleCopy}
          />{" "}
          <Button
            link="#buynow"
            classes="bgcolor relative z-[1] rounded-full w-[100%] h-[45px]  sm:h-[30px]  xs:h-[30px] text-[16px] sm:text-[16px]  xs:text-[16px] font-[700]  m-auto "
            onClick={() => disconnect.disconnect()}
            text="Disconnect"
          />{" "}
        </div>
      </div>
    </>
  )
}

export default PersonalDashboard